<ex-dropdown-v2 [(value)]="assignee" (dropdownToggle)="$event && updateAssignment()">
  <ng-template exDropdownV2Header let-value let-toggle="toggle" let-open="open">
    <div class="assignment-header" [hidden]="hidden && !open">
      <ex-button *ngIf="!value" class="assignment-button" type="tertiary" [size]="size" icon="user-circle-plus"
                 (click)="toggle()"
                 [ngbTooltip]="'tenders.assignment.assign-to' | translate" tooltipClass="simple-tooltip fit"
                 [disableTooltip]="open" [openDelay]="500" placement="right bottom-right auto" />
      <ex-user-badge *ngIf="value" [user]="value" [size]="size" (click)="toggle()"
                     [ngbTooltip]="'tenders.assignment.assigned-to' | translate: {name: value.name}"
                     [disableTooltip]="open" [openDelay]="500" placement="right bottom-right auto"
                     tooltipClass="simple-tooltip align-left"/>
    </div>
  </ng-template>
  <ng-template exDropdownV2Content let-value let-toggle="toggle">
    <div class="assignment-list shadow-sm">
      <app-assignment-list [selection]="assignee ? [assignee] : []"
                           (selectionChange)="assignTo($event.pop() ?? null);toggle(false)" />
    </div>
  </ng-template>
</ex-dropdown-v2>
