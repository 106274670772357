<ng-template #exportBar>
  <div *ngIf="tendersExportService.selection && !(tenderEntityService.tenderId$ | async)" class="export-bar"
       [ngClass]="{'disabled': tendersSearchService.inSearch}">
    <ex-button size="small" icon="x" (click)="tendersExportService.toggle(false)"/>
    <div class="selected text-truncate">
      {{ 'tenders.export.selected-tender' + (tendersExportService.selection.length > 1 ? 's' : '') | translate:
      { count: tendersExportService.selection.length } }}
    </div>
    <div class="d-flex gap-2 ms-auto">
      <ex-button type="secondary" variant="tonal" size="small"
                 (click)="tendersExportService.exportSelection(tendersSearchService, exportToast)"
                 [disabled]="!tendersExportService.selection.length || !!tendersExportService.export">
        {{ 'tenders.export.export-selection' | translate: { count: tendersExportService.selection.length } }}
      </ex-button>
      <ex-button type="secondary" size="small"
                 (click)="tendersExportService.exportAll(tendersSearchService, exportToast)"
                 [disabled]="!tendersSearchService.totalCount || !!tendersExportService.export ||
                    tendersSearchService.totalCount > 1000"
                 [ngbTooltip]="exportAllLimitToast" [openDelay]="500" tooltipClass="simple-tooltip"
                 [disableTooltip]="tendersSearchService.totalCount <= 1000" placement="bottom auto"
      >
        {{ 'tenders.export.export-all' | translate: { count: tendersSearchService.totalCount } }}
      </ex-button>
    </div>
  </div>
</ng-template>

<ng-template #exportAllLimitToast>
    <div class="text-start">{{'tenders.export.export-all-limit' | translate}}</div>
    <div class="text-start">{{'tenders.export.refine-search' | translate}}</div>
</ng-template>

<!--TODO rework toast-->
<ng-template #exportToast let-toast="toast" let-toastService="toastService">
  <div class="awaiting-toast-container no-select">
    <div class="left-part-toast">
      <div class="align-middle justify-content-center">
        <div class="waiting-circle-full"></div>
      </div>
    </div>
    <div class="center-part-toast">
      <div class="title color-primary-500 fw-bold">
        <span
          class="d-table-cell align-middle">{{ 'tenders.export.generating-excel' | translate | uppercase }}</span>
      </div>
      <div class="text fst-italic fw-bold color-basic-800">
        <span class="d-table-cell align-middle">{{ 'tenders.export.download-will-soon-start' | translate }}</span>
      </div>
      <div class="cancel-export color-basic-600 fw-bold d-table">
        <span class="d-table-cell align-middle"
              (click)="toastService.remove(toast); tendersExportService.export?.cancel()">
          {{ 'export.awaiting-export-toast-cancel' | translate }}
        </span>
      </div>
    </div>
    <div class="right-part-toast">
      <div class="hide-icon"
           ngbTooltip="{{'export.hide' | translate}}"
           placement="top">
        <mat-icon svgIcon="hide-600" class="icon-20 strict me-2"
                  (click)="toastService.hide(toast,
                                             exportToast,
                                            'toast-export-in-progress toast-shape',
                                            'list-export',
                                            lightToast)"
        >
        </mat-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #lightToast let-toast="toast" let-toastService="toastService">
  <div class="mini-toast-container d-flex">
    <div class="separator"></div>
    <div class="mini-toast" (click)="toastService.unhide(toast, 'list-export')">
      <div class="mini-waiting-circle d-table-cell align-middle align-content-center">
        <div class="waiting-circle-mini"></div>
      </div>
    </div>
  </div>
</ng-template>
